import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('formatDuration', () => ({
    duration(dur) {
        const seconds = dur % 60;
        const minutes = Math.floor((dur % 3600) / 60);
        const hours = Math.floor(dur / 3600);

        // Formatting rules:
        // -----------------
        // If `dur` is longer than or equals to 1 hour, then we add `hour` to the string.
        // If `hour` is added, then we zeropad `minutes`. If not, then we don't zeropad `minutes`
        // If `dur` is less than 1 minute, we zeropad `minutes`.
        // `seconds` are always zeropadded.

        const formattedDuration = [
            hours >= 1 ? hours : '',
            hours >= 1 && minutes < 10 ? minutes.toString().padStart(2, '0') : (hours >= 1 && minutes >= 10) || dur < 60 ? minutes.toString().padStart(1, '0') : minutes,
            // hours >= 1 || dur < 60 ? minutes.toString().padStart(1, '0') : minutes,
            seconds.toString().padStart(2, '0'),
        ];

        return formattedDuration.filter(x => x).join(':');
    }
}));
